export default [
  'Secondary School Diploma',
  'Computer Science',
  'Business Administration',
  'Mechanical Engineering',
  'Accounting',
  'Finance',
  'Economics',
  'Information Technology',
  'Electrical Engineering',
  'Business',
  'Computer Engineering',
  'Business Management',
  'Management',
  'Psychology',
  'Industrial Engineering',
  'Civil Engineering',
  'Engineering',
  'Information Systems',
  'English',
  'Mathematics',
  'Software Engineering',
  'Nursing',
  'Political Science',
  'Applied Science',
  'Project Management',
  'Chemical Engineering',
  'Criminal Justice',
  'Commerce',
  'Electronics',
  'Communications',
  'Biology',
  'Business Analytics',
  'Chemistry',
  'History',
  'Physics',
  'Sociology',
  'Communication',
  'Arts',
  'Journalism',
  'Data Science',
  'Law',
  'Engineering Management',
  'International Business',
  'Public Administration',
  'Computer Applications',
  'Biochemistry',
  'Education',
  'Architecture',
  'General Studies',
  'Computer Information Systems',
  'Public Health',
  'Technology',
  'Statistics',
  'Information Management',
  'Information Systems Management',
  'Human Resources',
  'Liberal Arts',
  'Petroleum Engineering',
  'Graphic Design',
  'Human Resource Management',
  'Aerospace Engineering',
  'Biomedical Engineering',
  'Industrial',
  'Philosophy',
  'Advertising',
  'Data Analytics',
  'International Relations',
  'Cybersecurity',
  'Public Relations',
  'Geology',
  'Telecommunications',
  'Mass Communication',
  'Administration',
  'Applied Business',
  'Film',
  'Management Studies',
  'Communication Arts',
  'Applied Accounting',
  'Kinesiology',
  'Sports Management',
  'Human Services',
  'Technology Management',
  'Microbiology',
  'Biotechnology',
  'Informatics',
  'Organizational Leadership',
  'Machine Learning',
  'Analytics',
  'Agriculture',
  'Counseling',
  'Anthropology',
  'Chinese',
  'Social Work',
  'Entrepreneurship',
  'Liberal Studies',
  'French',
  'Botany',
  'Pharmaceutical Sciences',
  'International Studies',
  'Photography',
  'Music',
];
